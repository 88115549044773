<template>
    <!--div id="header">
        <router-link to="/">
            <img src="../assets/logo_entretantocuento.png" alt="#EntreTantoCuento" id="header_logo">
        </router-link>
        <img @click="togglemenu" id="icono_menu" src="../assets/icono_menu.svg">
        <ul id="header_enlaces">
            <li><router-link to="/">Inicio</router-link></li>
            <li><router-link to="/formate">Fórmate</router-link></li>
            <li><router-link to="/pruebate">Pruébate</router-link></li>
            <li><router-link to="/difunde">Difunde</router-link></li>
            <li><router-link to="/comparte">Comparte</router-link></li>
        </ul>
    </div-->

  <div id="contenedor">
    <div id="borde">
        <div id="header-inicio">
            <img src="../assets/logo_entretantocuento.svg" alt="#EntreTantoCuento" id="header_logo_img">
            <div id="header_icons">
                <img src="../assets/icono_speaker.svg" @click="soundButton.play()" alt="Sonido" class="icono">
                <router-link to="/"><img @click="soundButton.play()" src="../assets/icono_home.svg" alt="Inicio" class="icono"></router-link>
            </div>
        </div>

        <div id="completo">
            <div id="topcontainer">
                <img src="../assets/logo_pruebate.svg" alt="Pruebate" id="logo_pruebate">
                <img src="../assets/logo_personajes_sm.svg" id="logo_personajes_sm">
                <img src="../assets/logo_personajes_lg.svg" id="logo_personajes_lg">
            </div>

            <div id="bottomcontainer">
                <div id="parrafo">
                    ¿Eres capaz de identificar cuando estás consumiendo información falsa en Internet? Prueba tus habilidades para encontrar noticias falsas, imágenes editadas o noticias fuera de contexto y demuestra que no caes #EntreTantoCuento
                </div>

                <img @click="soundButton.play(); initialize_game()" src="../assets/boton_jugar.png" alt="Jugar" class="boton">
                <div id="botones">
                    <router-link to="/ranking"><img src="../assets/boton_ranking.png" @click="soundButton.play()" alt="Ranking" class="boton"></router-link>
                    <img @click="soundButton.play(); openinst()" src="../assets/boton_instrucciones.png" alt="Instrucciones" class="boton">
                </div>

                <div id="disclaimer">
                    Recomendamos utilizar Chrome o Firefox para una experiencia óptima.
                    <img src="../assets/logo_patrocinadores.png" id="logo_sponsors">
                </div>
            </div>
        </div>
    </div>

    <div id="instrucciones-view">
        <div class="overlay" @click="soundButton.play(); closeinst()">
            <div class="class-instrucciones">
                <img src="../assets/icono_cerrar.svg" alt="Cerrar" id="icon_close">
                <div class="step">
                    <img src="../assets/swipe.gif" class="gif">
                    <div class="texto">
                        Desliza las noticias: Mueve tu dedo hacia la derecha si confías en la información que estás leyendo o hacia la izquierda si crees que estás leyendo información falsa o “fake news”.
                    </div>
                </div>
                <div class="step">
                    <img src="../assets/suma.gif" class="gif">
                    <div class="texto">
                        Suma puntos: Entre más respuestas correctas, acumularás más puntos. Se considera una respuesta correcta cuando confías en la información de noticias verdaderas o cuando desconfías de las noticias falsas. ¿podrás identificarlas?
                    </div>
                </div>
                <div class="step">
                    <img src="../assets/multiplica.gif" class="gif">
                    <div class="texto">
                        Multiplica puntos: Si logras encadenar una racha de respuestas correctas, recibirás una bonificación en los próximos puntos que obtengas.
                    </div>
                </div>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
import useJuego from '../store/contenidoJuego'

export default {
  mounted () {
    // this.soundMenu.play()
  },
  data () {
    return {
      soundButton: document.querySelector('#soundButton'),
      soundMenu: document.querySelector('#soundMenu')
    }
  },
  methods: {
    initialize_game: function () {
      this.soundMenu.pause()
      const gameState = useJuego()
      gameState.shuffleNews()
      this.$router.push('/juego/noticia')
    },
    openinst: function () {
      const instiew = document.querySelector('#instrucciones-view')
      const morracos = document.querySelector('#logo_personajes_sm')
      instiew.style.display = 'block'
      morracos.style.zIndex = 0
    },
    closeinst: function () {
      const instiew = document.querySelector('#instrucciones-view')
      const morracos = document.querySelector('#logo_personajes_sm')
      instiew.style.display = 'none'
      morracos.style.zindex = 1
    },
    togglemenu: function () {
      document.querySelector('#header_enlaces').style.display = 'flex'
      document.querySelector('#icono_menu').style.display = 'none'
    }
  }
}
</script>

<style scoped>
#header {
    background-color: white;
    border-style: none solid solid solid;
    border-width: 3px;
    border-color: #0C1D3E;
    border-radius: 0px 0px 15px 15px;

    padding: 0px 70px;
    display: flex;
    align-items: center;
}
#header_logo {
    width: 150px;
    padding: 15px;
}
#icono_menu {
    display: none;
    margin-left: auto;
    margin-right: 20px;
}
#icono_menu:hover {
    cursor: pointer;
}
#header_enlaces {
    list-style: none;
    display: flex;
    flex-flow: wrap;
    align-items: center;
    margin-left: auto;
    font-size: 24px;
}
#header_enlaces li {
    margin: 6px 25px;
}
#header_enlaces a:link, a:visited {
    text-decoration: none;
    color: #0C1D3E;
}
#header_enlaces a:hover {
    text-decoration: none;
    color: #54ceea;
}
#header_enlaces a.router-link-exact-active {
    color: #54ceea;
    border-width: 3px;
    border-style: none none solid;
}

#instrucciones-view {
    display: none;
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
}
.overlay {
    background: rgba(51,51,51,0.9);
    position: absolute;
    height: 100%;
    width: 100%;
    z-index: 999999;

    display: flex;
    align-items: center;
    justify-content: center;
}
.class-instrucciones {
    width: 300px;
    height: 500px;
    background-color: white;
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    position: relative;
}
#icon_close {
    position: absolute;
    top: -40px;
    right: 10px;
    width: 30px;
    height: 30px;
}
#icon_close:hover {
    cursor: pointer;
}
.step {
    width: 100%;
    height: 150px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    border: 2px solid #0C1D3E;
    border-radius: 15px;
    padding: 10px;
}
.gif {
    width: 30%;
    height: auto;
}
.texto {
    width: 70%;
    height: 50%;
    font: 13px "Helvetica";
    text-align: right;
    display: flex;
    align-items: center;
    justify-content: center;
}

#contenedor {
    width: 100%;
    /*height: calc(100vh - 95px);   con header */
    height: calc(100vh - 0px);
    background-image: url("../assets/patron_skyline.svg"), url("../assets/patron_home.svg");
    /*background-image: url("../assets/patron_home.svg");*/
    background-color: #54ceea;
    background-size: 250%, cover;
    background-position: bottom, center;
    background-repeat: no-repeat, no-repeat;
    padding: 18px 20px;
}

#borde {
    border-radius: 25px;
    border: 2px solid white;
    margin: 0 auto;

    max-width: 1280px;
    height: 100%;
    padding: 33px 0px;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

#header-inicio {
    width: 100%;

    display: flex;
    justify-content: space-between;
}
#header_logo_img {
    width: 100px;
    height: auto;
    margin: 10px 15px;
}
#header_icons {
    display: flex;
    align-items: center;
    margin-right: 10px;
}
.icono {
    width: 30px;
    height: auto;
    margin-right: 15px;
}
.icono:hover {
    cursor: pointer;
    transform: scale(1.1);
}

#completo {
    height: 100%;
}

#topcontainer {
    height: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}
#logo_pruebate {
    width: auto;
    height: 50%;
    padding: 0px 0px 20px;
}
#logo_personajes_sm {
    width: 90%;
    height: auto;
    position: relative;
    z-index: 1;
    transition-duration: 200ms;
}
#logo_personajes_sm:hover {
    transform: scale(1.1);
}
#logo_personajes_lg {
    display: none;
    transition-duration: 200ms;
}
#logo_personajes_lg:hover {
    transform: scale(1.1);
    transform-origin: bottom;
}

#bottomcontainer {
    height: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}
#parrafo {
    background-color: #0C1D3E;
    color: white;
    width: 80%;
    margin: -8px auto 0px auto;
    padding: 10px;
    border-radius: 10px;
    font-family: "Helvetica";
    font-size: 11px;
    text-align: justify;
    text-justify: inter-word;
}
.boton {
    height: 45px;
    width: auto;
}
.boton:hover {
    cursor: pointer;
    transform: scale(1.1);
}
#botones {
    width: 80%;
    display: flex;
    justify-content: space-between;
}

#disclaimer {
    width: 100%;
    font: 11px "Helvetica";
    color: white;
    margin-bottom: -3px;
}
#logo_sponsors {
    margin: 0px -3px -25px -3px;
    width: 100%;
}

@media screen and (max-width: 820px) {
    #header {
        padding: 10px;
    }
    #header_logo {
        width: 120px;
        padding: 15px;
    }
    #icono_menu {
        display: block;
    }
    #header_enlaces {
        display: none;
    }
}

@media screen and (min-width: 800px) {
    #contenedor {
        width: 100%;
        /*height: calc(100vh - 95px);   con header */
        height: calc(100vh - 0px);
        background-image: url("../assets/patron_skyline.svg"), url("../assets/patron_home.svg");
        /*background-image: url("../assets/patron_home.svg");*/
        background-color: #54ceea;
        background-size: 100%, cover;
        background-position: bottom, center;
        background-repeat: no-repeat, no-repeat;
        padding: 18px 20px;
    }

    #completo {
        height: 100%;
        display: flex;
        flex-direction: row;
    }
    #topcontainer {
        height: 100%;
        width: 60%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
    }
    #logo_personajes_sm {
        display: none;
    }
    #logo_personajes_lg {
        display: block;
        height: 70%;
        margin-bottom: -20px;
    }

    #bottomcontainer {
        height: 100%;
        width: 40%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
    }
    #parrafo {
        background-color: #0C1D3E;
        color: white;
        width: 80%;
        margin: 0px auto;
        padding: 15px;
        border-radius: 10px;
        font-family: "Helvetica";
        font-size: 20px;
        text-align: justify;
        text-justify: inter-word;
        order: 2;
    }
    .boton {
        height: 80px;
        width: auto;
        order: 2;
    }
    #botones {
        width: 80%;
        display: flex;
        justify-content: space-around;
        order: 1;
    }

    #disclaimer {
        margin-right: -10px;
        width: 100%;
        font: 13px "Helvetica";
        color: white;
        order: 2;
    }

    .class-instrucciones {
        width: 900px;
        height: 290px;
        background-color: white;
        border-radius: 15px;
        display: flex;
        flex-direction: row;
        flex-flow: nowrap;
        align-items: center;
        justify-content: space-around;
        padding: 20px;
    }
    .step {
        width: 250px;
        height: 250px;
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        align-items: center;
        justify-content: space-between;
        border: 2px solid #0C1D3E;
        border-radius: 15px;
        padding: 0px 10px 10px;
    }
    .gif {
        width: 150px;
        height: 50%;
        object-fit: contain;
    }
    .texto {
        width: 100%;
        height: 50%;
        font: 14px "Helvetica";
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}
</style>
